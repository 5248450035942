import React from "react";
import Project from "../../components/work/Project";
import { graphql } from "gatsby";
import LayoutPage from "../../components/LayoutPage";

export default function ProjectTemplate({ data }) {
  const project = data.allWordpressWpProjects.edges[0];

  return (
    <LayoutPage>
      <div className="px-4">
        <Project project={project} />
      </div>
    </LayoutPage>
  );
}
export const query = graphql`
  query($slug: String!) {
    allWordpressWpProjects(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          id
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            category
            description
            tools
            url
            slide_image_1 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            slide_image_2 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            slide_image_3 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
