import PropTypes from "prop-types";
import React from "react";
import LayoutWrapper from "@components/LayoutWrapper";
import Header from "@components/header";
function LayoutPage({ children }) {
  return (
    <LayoutWrapper>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex flex-grow flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
          {children}
        </main>
      </div>
    </LayoutWrapper>
  );
}

LayoutPage.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutPage;
